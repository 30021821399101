import $ from 'jquery'
import router from '@/router';
import store from "@/state/store";
import {useNotification} from "@kyvg/vue3-notification";
import Swal from "sweetalert2";

const {notify} = useNotification();

export function formatMoney(id) {
    const value = document.getElementById(id).value;
    // Remove todos os caracteres que não sejam dígitos
    if (value === '') return;

    const decimal = toDecimal(value);
    return document.getElementById(id).value = 'R$ ' + decimal;
}

export function formatVolume(id) {
    const value = document.getElementById(id).value;
    if (value === '') return;

    const decimal = toDecimal(value);
    return document.getElementById(id).value = decimal;
}

export function toDecimal(value) {
    var cleanValue = value.replace(/\D/g, '');

    // Divide o valor em parte inteira e decimal
    var integerPart = cleanValue.substring(0, cleanValue.length - 2);
    const verify = integerPart.charAt(0);
    if (parseInt(integerPart) > 1 && verify[0] === '0') integerPart = integerPart.slice(1);
    if (integerPart === '' || integerPart == 0) integerPart = '0';
    var decimalPart = cleanValue.substring(cleanValue.length - 2);
    if (decimalPart.length < 2) decimalPart = '0' + decimalPart;

    // Adiciona separador de milhar
    integerPart = integerPart.replace(/(\d)(?=(\d{3})+$)/g, '$1.');

    return integerPart + ',' + decimalPart;
}

export function floatToDecimal(value) {
    if (value == 0) {
        return value
    } else if (value && parseFloat(value) !== 'NaN') {
        const convert = new Intl.NumberFormat('pt-BR', {style: 'decimal', minimumFractionDigits: 2});

        return convert.format(value.toFixed(2));
    } else {
        return '';
    }
}

export function getUrl(session) {
    const values = JSON.parse(localStorage.getItem(session));
    let url = values.url + '?';
    const params = values.params;
    const keys = Object.keys(values.params);

    if(url == 'customers?'){
        url += 'withNotEmpty[]=tanks&'
    }

    url += values.params.order === 'asc' ? `orderByAsc=${values.params.order_by}` : `orderByDesc=${values.params.order_by}`;

    for (let i = 0; i < keys.length; i++) {
        if (keys[i] !== 'order' && keys[i] !== 'order_by') {
            let result = params[keys[i]];
            if (result !== '') url += `&${keys[i]}=${result}`;
        }
    }

    return url;
}

export function showLoading(page, loadind) {
    document.getElementById(loadind).style.display = 'block';
    document.getElementById(page).style.opacity = '.2';
}

export function closeLoading(page, loadind) {
    setTimeout(() => {
        document.getElementById(loadind).style.display = 'none';
        document.getElementById(page).style.opacity = '1';
    }, 300)
}

export function startLoading(id, button) {
    if (document.getElementById('spinner')) document.getElementById('spinner').style.display = 'block';
    if (document.getElementById(id)) document.getElementById(id).style.opacity = '.2';
    if (button) document.getElementById(button).setAttribute('disabled', true);
}

export function endLoading(id, button) {
    setTimeout(function () {
        if (document.getElementById('spinner')) document.getElementById('spinner').style.display = 'none';
        if (document.getElementById(id)) document.getElementById(id).style.opacity = '1';
        if (document.getElementById(button)) document.getElementById(button).removeAttribute('disabled');
    }, 300)
}

export function formDataToJson(id) {
    var $form = $(`#${id}`);
    var unindexed_array = $form.serializeArray();
    var indexed_array = {};

    $.map(unindexed_array, function (n) {
        if (n['name'].indexOf("[]") != -1) {// é um multiselect
            let field = n['name'].replace("[]", "");
            if (indexed_array[field] == undefined) {
                indexed_array[field] = [];
            }
            indexed_array[field].push(n['value']);
        } else {
            indexed_array[n['name']] = n['value'];
        }
    });
    return indexed_array;
}

export function Forbidden(response) {
    if (response.response.status === 401) {
        notifyError('Sessão expirada!');
        localStorage.removeItem('token');
        router.push({
            name: 'login'
        })
    }

    if (response.response.status === 500) {
        notifyError(response.data);
    }
}

export function showNotify(response) {
    if (response.status === 200 || response.status === 201) {
        notifySuccess(response.data);
    } else {
        Forbidden(response)
        notifyError(response.data);
    }
}

export function ValidateForm(id) {

    const form = document.getElementById(id);
    form.classList.add('was-validated');
    const inputs = form.querySelectorAll ('.form-control, .form-check-input, .form-select');
    for (let i = 0; i < inputs.length; i++) {
        if (inputs[i].value === '' &&
            (inputs[i].getAttribute('required') === '' || inputs[i].getAttribute('required') === true)) {
            notifyError('Preencha todos os campos obrigatórios!')
            return false;
        }
    }

    return true;
}

export function notifyError(messsage) {
    notify({
        title: `<i class='bx bx-error'></i>` + messsage,
        position: 'bottom center',
        type: 'error',
        speed: 300,
        duration: 3000,
    });
}

export function notifySuccess(messsage) {
    notify({
        title: `<i class='bx bx-check-circle'></i>` + messsage,
        position: 'bottom center',
        type: 'success',
        speed: 300,
        duration: 3000,
    });
}

export function newOrder(order_by, session) {
    let obj = JSON.parse(localStorage.getItem(session))

    let icons = document.querySelectorAll('th i');
    for (let i = 0; i < icons.length; i++) {
        icons[i].style.opacity = '1';
    }

    if (obj.params.order_by === order_by) {
        let order = obj.params.order;
        order = order.charAt(0).toUpperCase() + order.slice(1);
        const id = order_by + order;

        document.getElementById(id).style.opacity = '0';

        obj.params.order = obj.params.order === 'asc' ? 'desc' : 'asc';
    } else {
        const id = order_by + 'Desc';

        document.getElementById(id).style.opacity = '0';

        obj.params.order = 'asc';
        obj.params.order_by = order_by;
    }

    localStorage.setItem(session, JSON.stringify(obj));

    const url = getUrl(session)
    store.dispatch('api/getApi', url);

}

export function loadAddress() {
    var cep = $('#zipCode').val().replace(/\D/g, '');
    var validacep = /^[0-9]{8}$/;
    if (validacep.test(cep)) {
        $.getJSON("https://viacep.com.br/ws/" + cep + "/json/?callback=?", function (dados) {
            if (!("erro" in dados)) {
                $("#neighborhood").val(dados.bairro);
                $("#address").val(dados.logradouro);
                $("#complement").val(dados.complemento);
                $("#city").val(dados.localidade);
                $("#state").val(dados.uf);
            }
        });
    }
}

export function maskCpfCnpj(value) {

    if (value.length <= 11) {
        return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } else {
        return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    }
}

export function getModalValues(data, form, button) {
    const keys = Object.keys(data);

    for (let i = 0; i < keys.length; i++) {
        if (Array.isArray(data[keys[i]])) {
            data[keys[i]].forEach(element => {
                let input = document.getElementById(keys[i] + element);
                if (!input) input = document.getElementById(keys[i] + element.id);
                if (input && input.type === 'checkbox') {
                    input.checked = true;
                } else {
                    if (input && data[keys[i]] !== '') input.value = data[keys[i]];
                }
            });
        } else {
            const input = document.getElementById(keys[i]);
            if (input && data[keys[i]] !== '') input.value = data[keys[i]];
        }
    }
    endLoading(form, button);
}

export function opacityByTag(id, tag, opacity, spinner, display) {
    const y = document.getElementById(spinner);
    if (y) y.style.display = display;
    const x = document.getElementById(id);
    if (x) {
        const a = x.getElementsByTagName(tag);
        for (let i = 0; i < a.length; i++) {
            a[i].style.opacity = opacity;
        }
    }
}

export function moneyToFloat(string) {
    string = string.replace('R$ ', '');
    string = string.replaceAll('.', '');
    return parseFloat(string.replaceAll(',', '.'));
}

export async function deleteElement() {
    return Swal.fire({
        title: "Você tem certeza?",
        text: "Seus dados serão removidos e não poderão mais ser recuperados.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
    }).then((result) => {
        return result;
    });
}

export function loadTable() {
    const tbody = document.querySelector('#table tbody');
    if (tbody) tbody.style.opacity = '.2'

    calcSpinner();
}

export function endLoadTable() {
    if (document.getElementById('spinner')) document.getElementById('spinner').style.display = 'none';
    const tbody = document.querySelector('#table tbody');
    if (tbody) tbody.style.opacity = '1'

    reCalcSpinner();
}

export function calcSpinner(id) {
    const placeholderElement = document.querySelector('.simplebar-placeholder');
    const width = parseInt(placeholderElement.offsetWidth) / 2 + 30;
    document.getElementById('spinner').style.right = `calc(50% - ${width}px)`
    if (document.getElementById(id)) document.getElementById(id).style.opacity = '.2';
    if (document.getElementById('spinner')) document.getElementById('spinner').style.display = 'block';
}

export function reCalcSpinner(id) {
    if (document.getElementById('spinner')) document.getElementById('spinner').style.display = 'none';
    if (document.getElementById(id)) document.getElementById(id).style.opacity = '1'
    document.getElementById('spinner').style.right = `calc(50% - 2.5rem)`
}

export function convertDate(date) {
    const data = new Date(date)

    return `${String(data.getDate()).padStart(2, '0')}/${String(data.getMonth() + 1).padStart(2, '0')}/${data.getFullYear()}`;
}

export function convertDateHour(date) {
    const data = new Date(date)

    return `${String(data.getDate()).padStart(2, '0')}/${String(data.getMonth() + 1).padStart(2, '0')}/${data.getFullYear()} ${String(data.getHours()).padStart(2, '0')}:${String(data.getMinutes()).padStart(2, '0')}`;
}

export function convertDateText(date) {
    const months = [
        'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ];

    const data = new Date(date)
    return `${String(data.getDate()).padStart(2, '0')} ${months[data.getMonth()]} ${String(data.getHours()).padStart(2, '0')}:${String(data.getMinutes()).padStart(2, '0')}`;
}
